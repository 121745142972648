import React, { useEffect, useState } from 'react';
import {
  RouteComponentProps,
  createMemorySource,
  createHistory,
  LocationProvider,
  Router,
} from '@reach/router';
import {
  ThemeProvider,
  createTheme,
  GlobalStyle,
  PresentationsAttractor,
  PresentationsMenu,
} from 'angel-ui';
import Spinner from 'react-spinkit';
import LandscapeViewer from './pages/collections/LandscapeViewer';
import { SpinnerWrap } from './layouts';

let source = createMemorySource('/');
let history = createHistory(source);
let navigate = history.navigate;

interface IPresentationSlideProps extends RouteComponentProps {
  presentationId?: string;
  slideId?: string;
}

const PresentationSlideRoute = (props: IPresentationSlideProps) => (
  <LandscapeViewer {...props} backPath="/presentations/" type="presentations" />
);

interface IPresentationProps extends RouteComponentProps {
  id?: string;
}

const PresentationRoute: React.FC<IPresentationProps> = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);

  useEffect(() => {
    async function getPresentations() {
      const endpoint =
        process.env.NODE_ENV === 'production'
          ? 'https://archive.gateshead.gov.uk/api/presentation-kiosk'
          : 'http://127.0.0.1:8000/api/presentation-kiosk';
      const res = await fetch(endpoint);

      if (res.status === 200) {
        const data = await res.json();
        setLoading(false);
        setData(data);
      }
    }

    getPresentations();
  }, []);

  const presentation = data.length ? data.find((i: any) => i.id === Number(id)) : {};
  if (loading) {
    return null;
  }

  return (
    <PresentationsAttractor
      images={[presentation.background_image_cloud_url]}
      handler={() => navigate('/presentations/' + presentation.id + '/' + presentation.slides[0].id)}
      topTitle={presentation.top_title}
      baseTitle={presentation.base_title}
      ctaText={presentation.button_text}
      baseTitleSettings={{ fontWeight: 100, size: 200 }}
      topTitleSettings={{ fontWeight: 100, size: 48, top: -50, right: 80 }}
    />
  );
};

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([] as any);

  useEffect(() => {
    async function getPresentations() {
      const endpoint =
        process.env.NODE_ENV === 'production'
          ? 'https://archive.gateshead.gov.uk/api/presentation-kiosk'
          : 'http://127.0.0.1:8000/api/presentation-kiosk';
      const res = await fetch(endpoint);

      if (res.status === 200) {
        const data = await res.json();
        setLoading(false);
        setData(data);
      }
    }

    getPresentations();
  }, []);

  const menuItems = data
    ? data.map((item: any) => {
        return { title: item.button_text, handler: () => navigate('/presentations/' + item.id) };
      })
    : [];

  //const backGroundImage = data.length ? [data[0].background_image_cloud_url] : [];
  const backgroundImage = require('./GA-bg.png')

  return (
    <LocationProvider history={history}>
      <ThemeProvider theme={createTheme}>
        <GlobalStyle />

        {loading ? (
          <SpinnerWrap>
            <Spinner name="three-bounce" fadeIn="none" color="#b78b1d" />
          </SpinnerWrap>
        ) : (
          <Router>
            <PresentationsMenu
              path="/"
              menuItems={menuItems}
              images={[backgroundImage]}
              topTitle="Select a Presentation"
            />

            <PresentationRoute path="/presentations/:id" />
            <PresentationSlideRoute path="/presentations/:presentationId/:slideId" />
          </Router>
        )}
      </ThemeProvider>
    </LocationProvider>
  );
};

export default App;
