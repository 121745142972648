/**
 * Book Collection Page
 * This is the page that will show the book so users can
 * interact with the collection piece.
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import { Flex, GALogo } from 'angel-ui';
import { RouteComponentProps, Link } from '@reach/router';
import Zoom from '../../components/zoom';
import { PrevButton, NextButton } from '../../components/nav-button';
import { SpinnerWrap, FooterLogo } from '../../layouts';
import { Footer } from '../../layouts/common';

const previewHeight = 'calc(100vh - 287px)';

const ButtonWrap = styled(Flex)`
  background-color: #ffffff;
  padding: 24px 48px;
  justify-content: space-between;
  border-top: 1px solid ${props => props.theme.colors.grey};
`;

const PageWrap = styled.div`
  background-color: #fff;
  position: relative;
`;

const FullImage = styled.img`
  width: 100%;
  height: ${previewHeight};
  object-fit: cover;
`;

const SplitImage = styled.img`
  display: inline-block;
  width: 50%;
  height: ${previewHeight};
  object-fit: cover;
`;

const Wrap = styled.div`
  width: 100%;
  height: ${previewHeight};
  overflow: hidden;
  margin: 0 auto;
  position: relative;

  div > div {
    bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .iconButton {
    height: 50px;
    width: 48px;
    font-size: 1rem;
    background: #fff;
    border: 1px solid #d8d8d8;
    border-radius: 0;
    margin: 0 8px 0 0 !important;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const SlideTitle = styled.p`
  font-size: 35px;
  margin: 0;
  padding: 0;
  text-align: center;
  font-weight: 100;
`;

const SlideSubTitle = styled.p`
  font-size: 20px;
  font-weight: 100;
  margin: 0;
  padding: 0;
  text-align: center;
`;

interface ILandscapeProps extends RouteComponentProps {
  backPath: string;
  type: string;
  presentationId?:string
  slideId?: string;
}

interface IData {
  id: number | null;
  title: string;
  subtext: string;
  cloud_url: string;
  alt_cloud_url: string;
}

const LandscapeViewer: React.FC<ILandscapeProps> = ({ presentationId, slideId, type }) => {
  const defaultData: IData = {
    id: null,
    title: '',
    subtext: '',
    cloud_url: '',
    alt_cloud_url: '',
  };

  const [data, setData] = useState<IData>(defaultData);
  const [loading, setLoading] = useState(true);
  const [collection, setCollection] = useState<Array<IData>>([]);
  const [key, setKey] = useState(0);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    async function getData() {
      const endpoint =
        process.env.NODE_ENV === 'production'
          ? 'https://archive.gateshead.gov.uk/api/presentation-kiosk'
          : 'http://127.0.0.1:8000/api/presentation-kiosk';
      const res = await fetch(endpoint);

      if (res.status === 200) {
        const presentations = await res.json();
        const presentation = presentations.find((e:any) => e.id === Number(presentationId))
        const refData = presentation.slides.find((e:any) => e.id === Number(slideId));

        if (index !== 0) {
          setIndex(0);
        }

        setData(refData);
        setCollection(presentation.slides);
        setKey(presentation.slides.findIndex((e:any) => e.id === Number(slideId)));
        setLoading(false);
      }
    }

    getData();
  }, [presentationId, slideId, type, index]);

  return (
    <PageWrap>
      {loading ? (
        <SpinnerWrap>
          <Spinner name="three-bounce" fadeIn="none" color="#b78b1d" />
        </SpinnerWrap>
      ) : (
        <>
          <Wrap>
            {data.alt_cloud_url ? (
              <>
                <Zoom>
                  <div>
                    <SplitImage src={data.cloud_url} />
                    <SplitImage src={data.alt_cloud_url} />
                  </div>
                </Zoom>
              </>
            ) : (
              <Zoom>
                <FullImage src={data.cloud_url} />
              </Zoom>
            )}
          </Wrap>

          <ButtonWrap>
            <div style={{ width: 170 }}>
              {key > 0 && (
                <StyledLink
                  to={`/${type}/${presentationId}/${collection[key - 1].id}`}
                  onClick={() => setKey(key - 1)}
                >
                  <PrevButton>Previous</PrevButton>
                </StyledLink>
              )}
            </div>
            <div>
              <SlideTitle>{data.title}</SlideTitle>
              <SlideSubTitle>{data.subtext}</SlideSubTitle>
            </div>
            <div style={{ width: 170 }}>
              {key + 1 !== collection.length && (
                <StyledLink
                  to={`/${type}/${presentationId}/${collection[key + 1].id}`}
                  onClick={() => setKey(key + 1)}
                >
                  <NextButton>Next</NextButton>
                </StyledLink>
              )}
            </div>
          </ButtonWrap>

          <Footer>
            <Flex>
              <FooterLogo>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <GALogo />
                </Link>
              </FooterLogo>
            </Flex>
          </Footer>
        </>
      )}
    </PageWrap>
  );
};

export default LandscapeViewer;
